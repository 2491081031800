<template>
  <div>
    <b-row>
      <b-col cols="4">
        <div class="text-info-header">จุดเจาะเลือดที่ถูกปิดไปจาก Ls</div>
      </b-col>
      <b-col cols="4">
        <div class="text-info-header-2">เวลาอัพเดท</div>
      </b-col>
    </b-row>
    <b-row v-for="item in items" :key="item.id" class="mt-2">
      <b-col cols="4">
        <div class="text-info">{{ item.name || "-" }}</div>
      </b-col>
      <b-col cols="4">
        <div class="text-info">
          {{
            item.deletedDate
              ? $moment(item.deletedDate).format("DD/MM/YYYY hh:mm:ss")
              : "-"
          }}
        </div>
      </b-col>
    </b-row>
    <b-row v-if="items.length === 0">
      <b-col> <div class="no-items-box">ไม่มีสาขาที่ปิดใช้งาน</div> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
