<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="4">
        <p class="text-info-header">จุดเจาะเลือด</p>
      </b-col>
      <b-col cols="4">
        <p class="text-info-header-2">ราคา</p>
      </b-col>
      <b-col cols="4">
        <p class="text-info-header-2">เปิดใช้งาน</p>
      </b-col>
    </b-row>

    <b-row v-for="(item, index) in items" :key="item.id" class="mt-2">
      <b-col cols="4">
        <div class="text-info">{{ item.name || "-" }}</div>
      </b-col>
      <b-col cols="4">
        <InputText
          v-model="item.price"
          class="input mb-0"
          textFloat=""
          placeholder=""
          type="number"
          name="price"
          isRequired
          :v="v.$each[index].price"
          :isValidate="v.$each[index].price.$error"
        />
      </b-col>
      <b-col cols="4">
        <b-form-checkbox
          v-model="item.enable"
          switch
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="items.length === 0">
      <b-col> <div class="no-items-box">ไม่มีสาขาที่เปิดใช้งาน</div> </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
