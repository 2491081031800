<template>
  <div class="product-detail-setting">
    <div>
      <p class="text-header">{{ data.name }}</p>
    </div>

    <template v-if="isBusy">
      <div class="text-center text-black my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </template>

    <template v-else>
      <ProductActive :items="activeGroup" :v="$v.activeGroup" class="mt-4" />
      <ProductInactive :items="inActiveGroup" class="mt-4" />

      <div class="tw-mt-6 tw-flex tw-justify-between">
        <b-button class="btn-filter btn cancel" @click="goBackRouter">
          Cancel
        </b-button>
        <b-button
          class="btn-filter save"
          :disabled="isLoading"
          @click="saveProductPrice"
          >Save
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="isLoading"
          ></b-spinner
        ></b-button>
      </div>
    </template>

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ProductInactive from "../component/ProductInactive.vue";
import ProductActive from "../component/ProductActive.vue";

export default {
  components: { ModalAlertText, ProductInactive, ProductActive },
  data() {
    return {
      productId: undefined,
      productType: undefined,
      data: {
        id: 0,
        productType: "",
        name: "",
        description: "",
        price: 0,
        branchList: [],
        preparation: null,
        testList: [],
      },
      isLoading: false,
      activeGroup: [],
      inActiveGroup: [],

      // state modal
      textModal: "",
      arrStatus: [],

      // state table
      isBusy: false,
    };
  },
  validations() {
    return {
      activeGroup: {
        $each: {
          price: { required, minValue: minValue(1) },
        },
      },
    };
  },
  async created() {
    this.productId = this.$route.params.productId;
    this.productType = this.$route.params.productType;
    if (this.productId && this.productType) {
      await this.getProductDetail();
    }
  },
  methods: {
    async getProductDetail() {
      this.isBusy = true;
      const data = await this.$services.productsetting.getProductDetail({
        productId: this.productId,
        productType: this.productType,
      });

      if (data.result == 1) {
        this.data = data.detail;
        this.activeGroup = this.data.branchList.filter((el) => !el.isDeleted);
        this.inActiveGroup = this.data.branchList.filter((el) => el.isDeleted);
      }

      this.isBusy = false;
    },

    saveProductPrice() {
      this.$v.$touch();

      if (this.$v.$error) return;
      this.isLoading = true;
      const activeItems = this.activeGroup.map((el) => {
        return {
          id: el.id,
          price: el.price,
          enable: el.enable,
        };
      });

      const payload = {
        productId: this.data.id,
        productType: this.data.productType,
        branchList: [...activeItems],
      };

      this.submitProductPrice(payload);
    },
    async submitProductPrice(payload) {
      const respone = await this.$services.productsetting.saveProductSetting(
        payload
      );
      let text = "";
      if (respone.result === 1) {
        text = respone.detail.message;
      } else {
        text = respone.message;
      }
      this.isLoading = false;

      this.openModalAlertText(text);
      this.$v.$reset();
      this.getProductDetail();
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
    goBackRouter() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-setting {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  :deep(.text-info-header) {
    color: var(--font-main-color) !important;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
  }

  :deep(.text-info-header-2) {
    color: var(--font-main-color) !important;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: normal;
    width: 100%;
  }

  :deep(.text-info) {
    color: var(--font-main-color) !important;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: normal;
    width: 100%;
  }

  :deep(.no-items-box) {
    margin-top: 16px;
  }

  .btn-filter {
    width: 100%;
    max-width: 150px;

    &.cancel {
      border: none;
      background: #606060;
      color: white !important;
    }

    &.save {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--secondary-color);
      border: none;
      color: white !important;
    }
    &.save:hover {
      background: var(--secondary-color);
      cursor: pointer;
    }
    &.cancel:hover {
      background: #606060;
      color: white !important;

      cursor: pointer;
    }
  }
}
</style>
